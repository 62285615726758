<template>
  <v-container fluid>
    <v-row dense>
      <snackbar refs="snackbar"></snackbar>
      <v-col cols="4">
        <v-text-field
          v-if="record.template"
          outlined
          dense
          label="Name"
          v-model="record.template.Name"
        ></v-text-field>
      </v-col>
    
      <v-col cols="4">
        <v-checkbox
          v-if="record.template"
          outlined
          dense
          v-model="record.template.Enabled"
          label="Enabled"
        ></v-checkbox>
      </v-col>
      <v-col cols="4">
        <v-checkbox
          v-if="record.template"
          outlined
          dense
          v-model="record.template.DefaultTemplate"
          label="Default Template?"
        ></v-checkbox>
      </v-col>
      <v-col cols="4">
        <v-select
          label="Select Type"
          outlined
          v-model="record.type"
          :items="['GPM', 'VMS',]"
        ></v-select>
      </v-col>
    </v-row>
    <v-row dense>
      <v-toolbar>
        <v-toolbar-title>Manage Form Fields</v-toolbar-title>
      </v-toolbar>
    </v-row>
    <v-data-table
      v-model="selected"
      :loading="loading"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="selectedItems"
      :search="search"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:item.mandatory="{ item }">
        <v-btn class="btnLink" color="accent" text>{{
          item.mandatory == "Y" ? "Yes" : "No"
        }}</v-btn>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn icon :to="`/gpm/form-fields/${item.id}`">
          <v-icon color="green">mdi-eye</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.isSelected="{ item }">
        <v-checkbox
          v-model="item.isSelected"
          :value="item.isSelected"
        ></v-checkbox>
      </template>
    </v-data-table>

    <v-row dense>
      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-trash</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      record: {},
      selectedItems: [],
      headers: [
        { text: "Active", value: "isSelected" },
        { text: "ID", value: "id" },
        { text: "Key", value: "key" },
        { text: "Title", value: "title" },
        { text: "Mandatory", value: "mandatory" },
        { text: "Actions", value: "action" },
      ],
      loader: false,
      loading: false,
      fields: [],
      field_types: [],
      search: "",
      selected: [],
      status: true,
    };
  },
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },

  methods: {
    init(val) {
      console.log(val, "initial");
      this.loading = false;
      this.record = { ...val };
      this.record.type = val.template.type == 0 ? "GPM" :"VMS"
    },
    sendData() {
      let data = {};
      const FormFields = this.selectedItems
        .filter((element) => element.isSelected)
        .map((element) => element.id);

      data.FormFields = FormFields;
      data.Name = this.record.template.Name;
      data.Enabled = this.record.template.Enabled;
      data.type = this.record.template.Type == 'GPM' ? 0:1;
      data.DefaultTemplate = this.record.template.DefaultTemplate;
      data.id = this.record.template.id;

      console.log(data);
      this.loader = true;
      this.$emit("data", data, () => {
        alert(JSON.stringify(data));
        this.loader = false;
      });
    },
    getFormFields() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", "/form_fields")
        .then((res) => {
          console.log(res);
          self.fields = res.ResponseData;
          self.loading = false;
          self.selectedItems = self.fields.map((item1) => {
            const isSelected = self.record.fields.some(
              (item2) => item1.id === item2.id
            );
            return {
              ...item1,
              isSelected: isSelected,
            };
          });
          console.log(this.selectedItems);
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  mounted() {
    this.getFormFields();
  },
};
</script>
